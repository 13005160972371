import React from "react"
import useLockBodyScroll from './../hooks/useLockBodyScroll';
import ImageGallery from 'react-image-gallery';


const VideoOverlay = ({ handleClose, video }) => {
  const url = `https://player.vimeo.com/video/${video.vimeoID}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
  const posterImage = video.poster_image ? video.poster_image.publicURL : '';
  const categories = video.categories ? video.categories.map(category => category.category_name) : ''

  useLockBodyScroll();
  // if coming from home page or team member profile video.photography is most likely undefined, if coming in from our-work it will be an empty array
  const hasImages = video.photography && video.photography.length > 0;
  let images;

  if (hasImages) {
    images = video.photography.map(image => { 
      return { 
        original: image.url,
        originalAlt: image.alternativeText
      };
    });
  }
  
  return (
    <>
      <div className="vid-overlay-bkgd" 
        onClick={handleClose}
        onKeyPress={handleClose}
        role="button"
        tabIndex={0}
      > 
        {/* close */}
        <button 
          onKeyPress={handleClose}
          onClick={handleClose}
          tabIndex={0} 
          className="text-gray text-lg xl:text-2xl absolute top-6 right-6 flex justify-center items-center cursor-pointer">
          <p className="inline-block pr-4">close</p>
          <div className="w-7 h-7 xl:w-9 xl:h-9 relative inline-block">
            <div className="w-0.5 h-full absolute left-1/2 bg-gray transform origin-center rotate-45"></div>
            <div className="w-0.5 h-full absolute left-1/2 bg-gray transform origin-center -rotate-45"></div>
          </div>
        </button>

        <div className="pt-7 md:pt-5 mx-auto w-11/12 md:w-4/6"
          role="presentation" 
          onClick={(e)=> {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
          onKeyDown={(e)=> {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
          >

          {/* IF IT'S A VIDEO  */}  
          {video.vimeoID && (
            <div className="relative h-0" style={ {paddingTop: '56.25%'} } >
              <iframe className="w-full h-full absolute top-0 left-0" src={url} width='1920' height='1080' frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" title={video.title} poster={posterImage}></iframe>
            </div>
          )}

          {/* IF IT'S PHOTOGRAPHY  */}    
          {hasImages &&  (
            <ImageGallery key={video.id} items={images} showThumbnails={false} showPlayButton={false}/>
          )} 

          <div className={`${hasImages ? 'pt-10' : 'pt-5'} md:grid grid-cols-2 gap-5` }>
            <div className="">
              <h4 className="font-medium text-pink text-xl md:text-2xl pb-.5 md:pb-0">{video.client}</h4>
              <h3 className="font-bold text-orange text-2xl md:text-3xl pb-3 md:pb-0">{video.title}</h3>  
            </div>
            <div className="text-white capitalize md:text-right self-end">
              {categories.length > 0 && (<h5 className="pb-1 md:pb-2">Categories:</h5>)}
              {categories && (<h5 className="font-bold text-xl md:text-2xl pb-5 md:pb-0">{categories.join(', ')}</h5>)}
            </div>
            {/* IF IT'S A VIDEO  */} 
            { !hasImages && ( 
              <>
              <ul className="m-0 text-white text-base">
                {video.animator && video.animator !== 'placeholder' && <li className="pb-2">Animator: {video.animator}</li>}
                {video.director && video.director !== 'placeholder' && <li className="pb-2">Director: {video.director}</li>}
                {video.editor && video.editor !== 'placeholder' && <li className="pb-2">Editor: {video.editor}</li>}
                {video.producer && video.producer !== 'placeholder' && <li className="pb-2 last:p-0">Producer: {video.producer}</li>}
                {video.photographer && video.photographer !== 'placeholder' && <li className="pb-2">Photographer: {video.photographer}</li>}
              </ul>
              <ul className="m-0 text-white text-base md:text-right">
                {video.retoucher && video.retoucher !== 'placeholder' && <li className="pb-2 last:p-0">Retoucher: {video.retoucher}</li>}
                {video.shooter && video.shooter !== 'placeholder' && <li className="pb-2 last:p-0">Shooter: {video.shooter}</li>}
              </ul>
            </>
            )}
            </div> 
        </div>
      </div>
    </>
  )
}

export default VideoOverlay