import React from "react";
import useToggleState from "./../hooks/useToggleState"
import YetiFurPatternPink from "./../images/yeti-fur-pink.jpg"
import VideoOverlay from "./../components/videoOverlay"
import Fade from "react-reveal/Fade"
import { Dialog } from "@reach/dialog";


const Video = ({ video }) => {
  const posterImage = video.poster_image ? video.poster_image.publicURL : '';
  const [overlayOpen, toggle] = useToggleState(false);
  const handleClick = () => {
    toggle();
  }
  
  return (
    <>
    <Fade duration={500}>
      <div 
        className="work-thumb relative lg:h-0 group transition duration-200 cursor-pointer flex flex-col" 
        role="button"
        tabIndex={-1} 
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        {/* THUMBNAIL  */}
        <div className="thumb-img-wrap lg:absolute lg:top-0 lg:left-0 w-full lg:h-full">
          <img src={posterImage} alt={`${video.title} thumbnail`} className="object-cover w-full h-full absolute top-0 left-0"/>
        </div>
        {/* ROLLOVER  */}
        <div className="
          flex-1
          lg:opacity-0 lg:group-hover:opacity-100
          transition duration-200
          bg-black 
          lg:absolute lg:top-0 lg:left-0 w-full lg:h-full 
          text-center
          flex flex-col justify-center
          py-5"
          style={ {backgroundImage: `url(${YetiFurPatternPink})`} }
        >
          <h5 className="font-black text-orange text-2xl xl:text-5xl px-7 pb-2">{video.client}</h5>
          <h5 className="font-black text-white text-1xl xl:text-2xl px-7">{video.title}</h5>
          <button className="hidden lg:inline-block btn mt-10 outline-none">{video.vimeoID ? 'Play Video' : 'View Photography'}</button>
        </div>
        
      </div>
    </Fade>
      
    <Dialog isOpen={overlayOpen} aria-label="overlay">
      <VideoOverlay handleClose={handleClick} video={video}  />   
    </Dialog>
        
    </>
  )
}

export default Video