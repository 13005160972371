import { useLayoutEffect } from 'react';

export default function useLockBodyScroll() {
  useLayoutEffect(() => {
   // Get original value of body overflow
   const originalStyle = window.getComputedStyle(document.body).overflow;
   const originalTouchStyle = window.getComputedStyle(document.body).touchAction
   // Prevent scrolling on mount
   document.body.style.overflow = 'hidden';
   document.body.style.touchAction = 'none';
   // Re-enable scrolling when component unmounts
   return () => {
     document.body.style.overflow = originalStyle;
     document.body.style.touchAction = originalTouchStyle;
   }
  }, []); // Empty array ensures effect is only run on mount and unmount
}