import * as React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Fade from "react-reveal/Fade";

const AllWorkFooter = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiYetiMessaging {
            message
          }
        }
      `}
      render={data => (
        <Fade duration={500}>
          <div className="text-center w-11/12 md:w-7/12 mx-auto">
            <ReactMarkdown 
                className="pb-12 pt-28 lg:pt-48
                text-4xl md:text-5xl lg:text-6.5xl font-bold gradient-text-wrap">
              {data.strapiYetiMessaging.message}
            </ReactMarkdown>
            <Link to="/our-work" className="btn bg-pink inline-block">View All Our Work</Link>
          </div>
        </Fade>
      )}
    />
  )
}

export default AllWorkFooter